import { aeroApiMng } from '@/plugins/axios.js';

const setAsReaded = (id) => {
  return aeroApiMng.post('/MailBoxesMessages/SetReadedStatus',
    { 
      id,
      isRead: true,
    },
  );
};

const updateMailMessageGroup = ({id, groupId}) => {
  return aeroApiMng.post('/MailBoxesMessages/UpdateMailMessageGroup',
    {
      id,
      groupId,
    },
  );
};

const getMailBoxesUpdates = () => {
  return aeroApiMng.get('/MailBoxesMessages/GetMailBoxesUpdates');  
};

export default {
  setAsReaded,
  updateMailMessageGroup,
  getMailBoxesUpdates,
};
