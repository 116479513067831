import emailApi from '@/api/email.js';

const state = {};

const mutations = {};

const actions = {
  setAsReaded(context, id) {
    return emailApi
      .setAsReaded(id)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  updateMailMessageGroup(context, payload) {
    return emailApi
      .updateMailMessageGroup(payload)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getMailBoxesUpdates() {
    return emailApi
      .getMailBoxesUpdates()
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  name: 'email',
  namespaced: true,
  state,
  mutations,
  actions,
};
