<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('MailBoxesRulesCreate')"
    @edit="(id) => onEdit('MailBoxesRulesEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'MailBoxesRules',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'MailBoxesRules',
      tableCaption: 'Правила для почтовых ящиков',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Правила для почтовых ящиков',
        },
      ],
      tableHeaders: [
        { text: 'Название правила', alias: 'name', order: 'name' },
        { text: 'Email адрес отправителя', alias: 'fromMail', order: 'fromMail' },
        { text: 'Текст в теме письма', alias: 'textInSubject', order: 'textInSubject' },
        { text: 'Email адрес', alias: 'emailAddress', order: 'emailAddress' },
        { text: 'Название группы сообщений почтового ящика', alias: 'mailboxGroupName', order: 'mailboxGroupName' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
